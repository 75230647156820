<script>
import SwiperCore, { Thumbs, Navigation, Pagination } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Thumbs, Navigation, Pagination]);

import Layout from "@/layouts/main.vue";
import appConfig from "../../../app.config";
import store from "@/state/store";
import API from "@/plugins/axios-method";

export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      user: store.getters["auth/userInfo"],
      sexArr: [
        {value: 'male', label: 'Homme'},
        {value: 'female', label: 'Femme'},
      ],
      firstName: '',
      lastName: '',
      userRole: '',
      phoneNumber: '',
      allPhoneNumbers: [],
      email: '',
      birthDate: '',
      createdAt: '',
      commercialCode: null,
      nicNumber: '',
      uin: '',
      sex: '',
      id: '',
      city: null,
      isSubmitting: false,
      isSubmitted: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''

    }
  },
  components: {
    Layout,
  },

  mounted() {
    this.getCurrentUserInfo()
  },
  methods: {
    getCurrentUserInfo(){
      const user = store.getters["auth/userInfo"]
      const role = user.roles[0]
      this.id = user.id
      this.city = user?.city?.name
      this.firstName = user.firstname ? user.firstname : ''
      this.lastName = user.lastname ? user.lastname : ''
      this.phoneNumber = user.phoneNumber
      this.email = user.email ? user.email : ''
      this.nicNumber = user.nicNumber ? user.nicNumber : ''
      this.uin = user.uin ? user.uin : ''
      this.sex = user.sex ? user.sex : ''
      this.commercialCode = user.codes[0]
      this.birthDate = user.birthDate?.split('T')[0]
      this.createdAt = user.createdAt ? user.createdAt : ''

      user.authorisedPhoneNumbers.map(apn => {
        this.allPhoneNumbers.push(apn)
      })
      switch (role) {
        case 'ROLE_ADMIN':
          this.userRole = 'Admin'
          break;
        case 'ROLE_SUPERVISOR':
          this.userRole = 'Superviseur'
          break
        case 'ROLE_COMMERCIAL':
          this.userRole = 'Commercial'
          break;
        case 'ROLE_USER':
          this.userRole = 'Membre'
          break;
        default:
          this.userRole = 'membre'
          break;
      }
    },
    getFormattedDate(date){
      return date.split('T')[0]
    },
    updateUser(){
      this.isSubmitting = true
      API.patch('/users/' + this.id, {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        birthDate: this.birthDate,
        uin: this.uin,
        nicNumber: this.nicNumber,
        sex: this.sex,
      })
          .then(response => {
            this.isSubmitting = false
            if (response.status === 200){
              this.$store.commit('auth/SET_CURRENT_USER', response.data)
            }
          })
          .catch(error => {
            this.isSubmitting = false
            console.log(error)
          })
    },
    updatePassword(){
      this.isSubmitting = true
      API.patch('/users/' + this.id + '/password', {
        oldPassword: this.oldPassword,
        rawPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      })
          .then(() => {
            this.$router.push('/logout')
          })
          .catch(error => {
            this.isSubmitting = false
            console.log(error)
          })
    },
  },
  computed: {
    getFullName(){
      return this.firstName + ' ' + this.lastName
    }
  }
};
</script>

<template>
  <Layout>
    <div class="profile-foreground position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg">
        <img
            src="@/assets/images/profile-bg.jpg"
            alt=""
            class="profile-wid-img"
        />
      </div>
    </div>
    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4">
      <div class="row g-4">
        <div class="col-auto">
          <div class="avatar-lg">
            <img
                src="@/assets/images/users/user-dummy-img.jpg"
                alt="user-img"
                class="img-thumbnail rounded-circle"
            />
          </div>
        </div>
        <!--end col-->
        <div class="col">
          <div class="p-2">
            <h3 class="text-white mb-1">{{getFullName}}</h3>
            <p class="text-white-75">{{userRole}}</p>
            <div class="hstack text-white-50 gap-1">
              <div class="">
                <i
                    class="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"
                ></i
                >Yaoundé, Cameroun
              </div>
              <!--              <div>-->
              <!--                <i-->
              <!--                    class="ri-building-line me-1 text-white-75 fs-16 align-middle"-->
              <!--                ></i-->
              <!--                >Themesbrand-->
              <!--              </div>-->
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">
            <!-- Nav tabs -->
            <ul
                class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                role="tablist"
            >
              <li class="nav-item">
                <a
                    class="nav-link fs-14 active"
                    data-bs-toggle="tab"
                    href="#overview-tab"
                    role="tab"
                >
                  <i class="ri-user-2-fill d-inline-block d-md-none"></i>
                  <span class="d-none d-md-inline-block">Vue d'ensemble</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                    class="nav-link fs-14"
                    data-bs-toggle="tab"
                    href="#activities"
                    role="tab"
                >
                  <i class="ri-phone-fill d-inline-block d-md-none"></i>
                  <span class="d-none d-md-inline-block">Numéros</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                    class="nav-link fs-14"
                    data-bs-toggle="tab"
                    href="#projects"
                    role="tab"
                >
                  <i class="ri-lock-fill d-inline-block d-md-none"></i>
                  <span class="d-none d-md-inline-block">Mise à jour profile</span>
                </a>
              </li>
            </ul>
          </div>
          <!-- Tab panes -->
          <div class="tab-content pt-4 text-muted">
            <div class="tab-pane active" id="overview-tab" role="tabpanel">
              <div class="row">
                <div class="col-xxl-3">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-5">Completez Votre Profil</h5>
                      <div
                          class="progress animated-progess custom-progress progress-label"
                      >
                        <div
                            class="progress-bar bg-danger"
                            role="progressbar"
                            style="width: 70%"
                            aria-valuenow="70"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                          <div class="label">70%</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-3">Info</h5>
                      <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                          <tbody>
                          <!-- <tr>
                            <th class="ps-0" scope="row">Code Commercial :</th>
                            <td class="h3">{{commercialCode?.name}}</td>
                          </tr> -->
                          <tr>
                            <th class="ps-0" scope="row">Nom :</th>
                            <td class="text-muted text-capitalize">{{getFullName}}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Sexe :</th>
                            <td class="text-muted">
                              <span v-if="sex === 'male'">Homme</span>
                              <span v-if="sex === 'female'">Femme</span>
                            </td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Date de naissance :</th>
                            <td class="text-muted">{{birthDate}}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Numéro principal :</th>
                            <td class="text-muted">{{ phoneNumber }}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">E-mail :</th>
                            <td class="text-muted">{{email}}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Numéro CNI :</th>
                            <td class="text-muted">{{nicNumber}}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Numéro d'indentification unique :</th>
                            <td class="text-muted">{{uin}}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Location :</th>
                            <td class="text-muted">
                              <span class="text-capitalize">{{city}}</span> , Cameroun
                            </td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Date de création</th>
                            <td class="text-muted">{{ getFormattedDate(createdAt) }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- end card body -->
                  </div>
                  <!--end card-->
                </div>
              </div>
              <!--end row-->
            </div>
            <div class="tab-pane fade" id="activities" role="tabpanel">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title mb-3">Numéros de Téléphone Validés</h5>
                </div>
                <!--end card-body-->
              </div>
              <!--end card-->
            </div>
            <!--end tab-pane-->
            <div class="tab-pane fade" id="projects" role="tabpanel">
              <div class="card mt-xxl-n5">
                <div class="card-header">
                  <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                        <i class="fas fa-home"></i>
                        Informations Personnelles
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                        <i class="far fa-user"></i>
                        Modifiez le mot de passe
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="card-body p-4">
                  <div class="tab-content">
                    <div class="tab-pane active" id="personalDetails" role="tabpanel">
                      <form action="javascript:void(0);">
                        <div class="row g-3">
                          <div class="col-md-6">
                            <div>
                              <label for="firstName" class="form-label">Nom</label>
                              <input type="text" class="form-control" id="firstName" v-model="lastName" placeholder="Nom">
                            </div>
                          </div><!--end col-->
                          <div class="col-md-6">
                            <div>
                              <label for="lastName" class="form-label">Prenom</label>
                              <input type="text" class="form-control" id="lastName" v-model="firstName" placeholder="Prenom">
                            </div>
                          </div><!--end col-->
                          <div class="col-md-6">
                            <label for="birtdate" class="form-label">Date naissance</label>
                            <input type="date" class="form-control" id="birthdate" v-model="birthDate">
                          </div>
                          <div class="col-md-6">
                            <label for="sex" class="form-label">Sexe</label>
                            <select v-model="sex" class="form-select mb-3">
                              <option disabled selected>Choisir le sex</option>
                              <option v-for="(sex, index) in sexArr" :key="index" :value="sex.value">{{ sex.label }}</option>
                            </select>
                          </div>
                          <div class="col-12">
                            <div>
                              <label for="emailInput" class="form-label">Email</label>
                              <input type="email" class="form-control" id="emailInput" placeholder="Email" v-model="email">
                            </div>
                          </div><!--end col-->

                          <div class="col-md-6">
                            <div>
                              <label for="nicNumber" class="form-label">Numéro CNI</label>
                              <input type="text" class="form-control" id="nicNumber" v-model="nicNumber" placeholder="Numéro CNI">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div>
                              <label for="uin" class="form-label">Numéro d'indentification unique</label>
                              <input type="text" class="form-control" id="uin" v-model="uin" placeholder="Numéro d'indentification unique">
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="hstack gap-2 justify-content-end">
                              <button type="submit" @click="updateUser" :disabled="isSubmitting" class="btn btn-primary">Enregistrer</button>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </form>
                    </div>
                    <!--end tab-pane-->
                    <div class="tab-pane" id="changePassword" role="tabpanel">
                      <form @submit.prevent="updatePassword">
                        <div class="row g-2">
                          <div class="col-lg-4">
                            <div>
                              <label for="oldpasswordInput" class="form-label">Ancien mot de passe*</label>
                              <input v-model="oldPassword" required type="password" class="form-control" id="oldpasswordInput" placeholder="Ancien mot de passe" />
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-lg-4">
                            <div>
                              <label for="newpasswordInput" class="form-label">Nouveau mot de passe*</label>
                              <input v-model="newPassword" required type="password" class="form-control" id="newpasswordInput" placeholder="Nouveau mot de passe" />
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-lg-4">
                            <div>
                              <label for="confirmpasswordInput" class="form-label">Confirmez le nouveau mot de passe*</label>
                              <input v-model="confirmPassword" required type="password" class="form-control" id="confirmpasswordInput" placeholder="Confirmez le nouveau mot de passe" />
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-lg-12">
                            <div class="text-end">
                              <button :disabled="isSubmitting" type="submit" class="btn btn-primary">
                                Changez mot de passe
                              </button>
                            </div>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </form>
                    </div>
                    <!--end tab-pane-->
                  </div>
                </div>
              </div>
              <!--end card-->
            </div>
          </div>
          <!--end tab-content-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
